import React from 'react'

const Header = () => {
  return (
    <header>
        <div class="d-flex">
            <div>
                <img src="img/logo-blanco.svg" alt=""></img>
            </div>
            <div>
                <img src="img/mvs-logo.svg" alt=""></img>
            </div>
        </div>
    </header>
  )
}

export default Header