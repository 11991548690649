import React from 'react';

import { QRCodeSVG } from 'qrcode.react';

const Qr = ({ code }) => { // Añade aquí la prop imageUrl
    const url = `https://wa.me/525540941025?text=Hola,%20quiero%20participar%20con%20el%20siguiente%20c%C3%B3digo%20de%20invitaci%C3%B3n%20${code}`
  return (
    <div>
         <a href={`whatsapp://send?text=${url}`}
        data-action="share/whatsapp/share"
        target="_blank"> 
        Share to whatsapp 
    </a> 
        <div className="qr_user" onClick={async()=>{await navigator.clipboard.writeText(url); alert('Listo');}}>
            <div className="svg-qr">
                <svg id="svg-qr" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.42 240.42">
                    <g id="Capa_1-2" data-name="Capa 1">
                        <g id="Rectángulo_7" data-name="Rectángulo 7">
                            <rect className="cls-1" x="35.21" y="35.21" width="170" height="170"
                                transform="translate(-49.79 120.21) rotate(-45)" />
                            <rect className="cls-4" x="37.71" y="37.71" width="165" height="165"
                                transform="translate(-49.79 120.21) rotate(-45)" />
                        </g>
                        <g id="Rectángulo_8" data-name="Rectángulo 8">
                            <rect className="cls-1" x="35" y="35" width="170" height="170" />
                            <rect className="cls-2" x="37.5" y="37.5" width="165" height="165" />
                        </g>
                        <rect id="Rectángulo_6" data-name="Rectángulo 6" className="cls-5" x="45.07" y="45.07" width="150"
                            height="150" transform="translate(-49.73 120.07) rotate(-45)" />
                        <g id="Rectángulo_1" data-name="Rectángulo 1">
                            <rect className="cls-3" x="47.5" y="47.5" width="145" height="145" />
                        </g>
                    </g>
                </svg>
            </div>
            <div className="img-qr" >
                <QRCodeSVG value={url} size={263} includeMargin={true} imageSettings={{src:"img/logo-color-100.jpg",excavate:true, height:40, width:40}}/>
            </div>
        </div>
    </div>
  );
}

export default Qr;
