import './App.css';

import {
  useEffect,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';

import Footer from './components/Footer';
import Header from './components/Header';
import Qr from './components/Qr';

function App() {
  const {userId} = useParams();

  const [user, setUser] = useState(null);

  const fetchInfo = async () => {
    try {
      const mongoIdRegex = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
      const isIdValid = mongoIdRegex.test(userId);

      if (!isIdValid) {
        setUser(null);
        return;
      }

      let response = await fetch(`https://apibotexamty.mvsradio.com/user/${userId}?authToken=ZCnxo811wAr2dlmpYvGysj6CsDvOrlqi`);
      let data = await response.json();
      setUser(data.data.userInfo);
    } catch (e) {
      setUser(null);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchInfo();
    })();
  }, [userId]); // Añadí userId como dependencia para refetch si el userId cambia

  const UserInfo = () => (
    <>
      
      <div class="user-date">
            {/*<h4 class="qr-valido-text">¡Felicidades el código QR se validó!</h4> */}
            <h3>{user.nombre}</h3>
            <br></br>
            <p><b>Tel:</b> {user.id}</p>
            <p><b>Correo:</b> &nbsp; {user.correo}</p>
        </div>
    </>
  );

  const NotFound = () => (
    <p className='Noencontrado'>Usuario no valido</p>
  );

  return (
    <div>
      <Header />
      

      <div class="contenido">
                    {/* <div class="logo-principal">
                        <img src="img/logo-color-100.jpg" alt=""></img>
                    </div> */}
                  <h2>Comparte tu enlace !</h2>
                    {user ? (
          <>
            <Qr code={user.code} />
            <UserInfo />
            <br></br>
            <div className="line">
              <hr />
            </div>
            <br></br>
            <div class="referidos">
            <div class="content-referidos">
                <div class="referidos-aprovados">
                    <br/>
                    <h3>Tienes {user.referidos.length} puntos</h3>
                    {user.referidos.map((referido,idx) => {
                      if(referido.completed){
                        return(<div class="card-referido">
                          <div class="data-referido">
                              <p class="titulo-referido">ID</p>
                              <p class="texto-referido">{idx +1}</p>
                          </div>
                          <div class="data-referido">
                              <p class="titulo-referido">Nombre</p>
                              <p class="texto-referido">{referido.nombre}</p>
                          </div>
                          <div class="data-referido">
                              <p class="titulo-referido">Teléfono</p>
                              <p class="texto-referido">{referido.id}</p>
                          </div>
                          <div class="data-referido">
                              <p class="titulo-referido">Fecha</p>
                              <p class="texto-referido">{new Date(referido.created).toDateString()}</p>
                          </div>
                          <div class="data-referido">
                              <p class="titulo-referido">Estado</p>
                              <p class="texto-referido">
                                Completado
                                <img src="img/aprovado.svg" alt=""/>
                              </p>
                          </div>
                       </div>)
                      }else{
                        return(<div class="card-referido">
                          <div class="data-referido">
                              <p class="titulo-referido">ID</p>
                              <p class="texto-referido">{idx +1}</p>
                          </div>
                          <div class="data-referido">
                              <p class="titulo-referido">Teléfono</p>
                              <p class="texto-referido">{referido.id}</p>
                          </div>
                          <div class="data-referido">
                              <p class="titulo-referido">Fecha</p>
                              <p class="texto-referido">{new Date(referido.created).toDateString()}</p>
                          </div>
                          <div class="data-referido">
                              <p class="titulo-referido">Estado</p>
                              <p class="texto-referido">
                                Incompleto
                                <img src="img/pendiente.svg" alt=""/>
                              </p>
                          </div>
                       </div>)
                      }
                      
                    })}
                </div>

                <br/><br/>
                
            </div>
            
        </div>
          </>
        ) : <NotFound />}

                </div>
      <Footer />
    </div>
  );
}

export default App;
