import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div>
            <img src="img/logo-blanco.png" alt=""></img>
        </div>
        <div class="legales">
            <a href="">Términos y condiciones</a> | <a href=""> Aviso de Privacidad</a>
        </div>

    </footer>
  )
}

export default Footer